/*
 * Component: Small Box
 * --------------------
 */

.small-box {
  @include border-radius($border-radius);
  @include box-shadow($card-shadow);
  @extend .mb-3;

  position: relative;
  display: block;
  margin-bottom: 20px;

  // content wrapper
  > .inner {
    padding: 10px;
  }

  > .small-box-footer {
    position: relative;
    text-align: center;
    padding: 3px 0;
    color: $white;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    z-index: 10;
    background: rgba(0, 0, 0, 0.1);
    text-decoration: none;
    &:hover {
      color: $white;
      background: rgba(0, 0, 0, 0.15);
    }
  }

  h3 {
    font-size: 38px;
    font-weight: bold;
    margin: 0 0 10px 0;
    white-space: nowrap;
    padding: 0;

  }

  p {
    font-size: 15px;
    > small {
      display: block;
      color: #f9f9f9;
      font-size: 13px;
      margin-top: 5px;
    }
  }

  h3, p {
    z-index: 5;
  }

  // the icon
  .icon {
    transition: all $transition-speed linear;
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 0;
    font-size: 90px;
    color: rgba(0, 0, 0, 0.15);
  }

  // Small box hover state
  &:hover {
    text-decoration: none;
    // Animate icons on small box hover
    .icon {
      font-size: 95px;
    }
  }
}

@include media-breakpoint-down(sm) {
  // No need for icons on very small devices
  .small-box {
    text-align: center;
    .icon {
      display: none;
    }
    p {
      font-size: 12px;
    }
  }
}

/*
 * Component: Progress Bar
 * -----------------------
 */

//General CSS
.progress {
  @include box-shadow(none);
  @include border-radius($progress-bar-border-radius);
}

.progress-group {
  @extend .mb-2;
}

// size variation
.progress-sm {
  height: 10px;
}

.progress-xs {
  height: 7px;
}

.progress-xxs {
  height: 3px;
}

// Vertical bars
.progress.vertical {
  position: relative;
  width: 30px;
  height: 200px;
  display: inline-block;
  margin-right: 10px;
  > .progress-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  //Sizes
  &.sm,
  &.progress-sm {
    width: 20px;
  }

  &.xs,
  &.progress-xs {
    width: 10px;
  }
  &.xxs,
  &.progress-xxs {
    width: 3px;
  }
}

// Remove margins from progress bars when put in a table
.table {
  tr > td .progress {
    margin: 0;
  }
}

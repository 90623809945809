/*
 * Component: Carousel
 * -------------------
 */
.carousel-control {
  &.left,
  &.right {
    background-image: none;
  }
  > .fa {
    font-size: 40px;
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -20px;
  }
}

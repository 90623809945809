/*
 * Component: Nav
 * --------------
 */
.nav-pills {
  .nav-link {
    color: $gray-600;

    &:not(.active):hover {
      color: theme-color("primary")
    }
  }
}

/*
 * Component: alert
 * ----------------
 */

.alert {
  .icon {
    margin-right: 10px;
  }

  .close {
    color: #000;
    opacity: .2;
    &:hover {
      opacity: .5;
    }
  }

  a {
    color: $white;
    text-decoration: underline;
  }
}

//Alert Variants
.alert-success {
  @extend .bg-success;
  border-color: darken(theme-color("success"), 5%);
}

.alert-danger,
.alert-error {
  @extend .bg-danger;
  border-color: darken(theme-color("danger"), 5%);
}

.alert-warning {
  @extend .bg-warning;
  border-color: darken(theme-color("warning"), 5%);
}

.alert-info {
  @extend .bg-info;
  border-color: darken(theme-color("info"), 5%);
}

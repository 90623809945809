/*
 * Page: Invoice
 * -------------
 */

.invoice {
  position: relative;
  background: $white;
  border: 1px solid $card-border-color;
}

.invoice-title {
  margin-top: 0;
}
